import { schema } from 'normalizr';

import { Library, LibraryService } from '@celum/libraries/api';
import { Entity, EntityRegistry, EntityTranslator, relatedEntities, RelationFunction } from '@celum/ng2base';
import { AssetInformation } from '@celum/portals/api';

export const CONTENT_TEMPLATE = `ContentTemplate`;

export interface TemplateLibraryConfig {
  /** Folder id which is used to store design assets for the template. */
  assetsFolderId: string;
  /** Folder id which is used to store the import files for import a file to the template. */
  importSourceFolderId?: string;
  /** Folder id which is used to store generated previews for the template assets. */
  previewFolderId: string;
  /** Asset id which points to the scene that defines the template. There might not be a scene created so this is optional! */
  sceneAssetId?: string;
}

export enum ContentTemplateStatus {
  ACTIVE = 'ACTIVE',
  PENDING_UPLOAD = 'PENDING_UPLOAD',
  IMPORTING = 'IMPORTING'
}

/**
 * Additional information about the template.
 *
 * Assets are referenced in scenes via the Id of the AssetInformation and NOT the Id of the Asset itself. This decoupling helps to keep the scene more stable
 * and flexible when it comes to new requirements (e.g. referencing multiple versions of the same asset within a scene).
 */
export type AssetMetadata = {
  /* The images referenced in the content template. */
  images: AssetInformation[];
};

export interface ContentTemplate extends Entity {
  name: string;
  /* ID of the library that was created for a content template. */
  templateLibraryId: string;
  /* Additional information about the template library ({@link templateLibraryId}). */
  templateLibraryConfig: TemplateLibraryConfig;
  attachedLibraryIds: string[];
  attachedLibraries?: RelationFunction<Library[]>;
  /** Whether the template is currently published. */
  isPublished: boolean;
  /** The version of the scene that is currently published. Be aware that version 0 is valid, use {@see isPublished} instead for published checks. */
  publishedVersion?: number;
  status: ContentTemplateStatus;
  /* Additional information about the template. Adapts urls within based on if the template is queried with a portalId parameter. */
  metadata: AssetMetadata;
  /** The url of the scene. Adapts based on if template is queried with a portalId parameter. */
  sceneUrl?: string;
  /** The preview connected to the scene. Adapts based on if template is queried with a portalId parameter. */
  previewUrl?: string;
}

const contentTemplateTranslator: EntityTranslator<ContentTemplate> = (json: any) => ({
  id: json.id,
  typeKey: CONTENT_TEMPLATE,
  name: json.name,
  templateLibraryId: json.templateLibraryId,
  templateLibraryConfig: json.templateLibraryConfig,
  metadata: json.metadata,
  publishedVersion: json.publishedData?.sceneVersion,
  isPublished: !!json.publishedData,
  attachedLibraryIds: json.attachedLibraryIds,
  attachedLibraries: relatedEntities<Library>(json.id, 'attachedLibraryIds'),
  status: json.status,
  sceneUrl: json.sceneUrl,
  previewUrl: json.previewUrl
});

const contentTemplateSchema = new schema.Entity<ContentTemplate>(CONTENT_TEMPLATE);

EntityRegistry.register<ContentTemplate>({
  typeKey: CONTENT_TEMPLATE,
  translator: contentTemplateTranslator,
  schema: contentTemplateSchema,
  resolveStrategy: { attachedLibraryIds: LibraryService }
});
