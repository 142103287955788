import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import Pusher, { Channel, ChannelAuthorizationCallback } from 'pusher-js';
import { ChannelAuthorizationData, ChannelAuthorizationRequestParams } from 'pusher-js/types/src/core/auth/options';
import { Observable, throwError } from 'rxjs';

import { DataUtil } from '@celum/core';

export interface PusherConfiguration {
  appKey: string;
  authUrl: string;
  userId?: string;
  userChannelPrefix?: string;
  channelPrefix?: string;
  enableLogging?: boolean;
}

@Injectable({ providedIn: 'root' })
export class PusherService {
  private httpClient = inject(HttpClient);

  private pusher: Pusher;
  private config: PusherConfiguration;
  private channel: Channel;
  private currentPusherConnectionState: string;

  public initialize(configuration: PusherConfiguration, userOid: string): void {
    this.config = configuration;

    if (configuration.enableLogging) {
      Pusher.log = console.log;
    }

    this.pusher = this.createPusher();
    this.currentPusherConnectionState = this.pusher.connection.state;
    this.bindToPusherEvents();

    this.subscribePrivateChannel(userOid);
  }

  public watchUserChannel<P>(eventName: string): Observable<P> {
    const channel = `${this.config.userChannelPrefix ?? ''}${this.config.userId}`;
    return this.bindToChannel(channel, eventName);
  }

  public watchChannel<P>(destination: string, eventName: string): Observable<P> {
    const channel = `${this.config.channelPrefix ?? ''}${destination}`;
    return this.bindToChannel(channel, eventName);
  }

  public watchPrivateUserChannel<P>(eventName: string): Observable<P> {
    if (['connected', 'connecting'].includes(this.currentPusherConnectionState)) {
      return this.bindToEvent(eventName);
    }

    console.warn('PusherService: cannot watch private channel, since connection is broken', this.currentPusherConnectionState);
    return throwError(() => new Error('PusherService: connection is not established'));
  }

  private createPusher(): Pusher {
    return new Pusher(this.config.appKey, {
      cluster: 'eu',
      channelAuthorization: {
        customHandler: this.authorizationHandler.bind(this),
        endpoint: undefined,
        transport: 'ajax'
      }
    });
  }

  private bindToPusherEvents(): void {
    this.pusher.connection.bind('state_change', (state: any) => {
      console.debug('PusherService: Connection state', state);
      this.currentPusherConnectionState = state.current;
    });

    this.pusher.bind('pusher:error', (error: any) => {
      console.error('PusherService: error, there will be no further events', error);
    });
  }

  /**
   * Subscribe to private channel initially and never unsubscribe to not be kicked-out by pusher (current settings).
   */
  private subscribePrivateChannel(userOid: string): void {
    const channel = `${this.config.channelPrefix ?? ''}${userOid}`;
    console.debug(`PusherService: subscribe to user private channel ${channel}`);
    this.channel = this.pusher.subscribe(channel);
  }

  private authorizationHandler(params: ChannelAuthorizationRequestParams, callback: ChannelAuthorizationCallback): void {
    console.debug('PusherService: calling authorization handler');

    this.httpClient.post<ChannelAuthorizationData>(this.config.authUrl, params).subscribe({
      next: result => {
        console.log('PusherService: authorization successful!', result);
        callback(null, result);
      },
      error: err => {
        console.error(`PusherService: authorization failed!`, err);
        callback(err, null);
      }
    });
  }

  private bindToEvent<T>(eventName: string): Observable<T> {
    if (!this.pusher) {
      console.error(`PusherService: channel can only be watched after calling "initialize"!`);
      return null;
    }

    return this.pusherBind(this.channel, eventName, false);
  }

  private bindToChannel<T>(channelName: string, eventName: string): Observable<T> {
    if (!this.pusher) {
      console.error(`PusherService: channel can only be watched after calling "initialize"!`);
      return null;
    }

    console.debug(`PusherService: subscribe to channel ${channelName}`);
    const channel = this.pusher.subscribe(channelName);

    return this.pusherBind<T>(channel, eventName);
  }

  private pusherBind<T>(channel: Channel, eventName: string, cleanupOnLastCallbackGone = true): Observable<T> {
    return new Observable<T>(subscriber => {
      const callback = (payload: T) => subscriber.next(payload);
      console.debug(`PusherService: bind to event ${eventName}`);
      channel.bind(eventName, callback);

      return () => {
        console.debug(`PusherService: unbind event ${eventName}`);
        channel.unbind(eventName, callback);

        if (cleanupOnLastCallbackGone && DataUtil.isEmpty(channel.callbacks._callbacks)) {
          console.debug(`PusherService: unsubscribe channel ${eventName}`);
          channel.unsubscribe(); // do not receive further messages if there are no bindings for any event on that channel
        }
      };
    });
  }
}
