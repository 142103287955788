import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  ColorConstants,
  IconConfiguration,
  ProgressSnackbar,
  RemoveSnackbar,
  ShowSnackbar,
  SimpleSnackbar,
  SnackbarConfiguration,
  SnackbarState
} from '@celum/common-components';
import { ExecutableAction } from '@celum/core';

export function showFirstStepSnackbar(store: Store, file: File, snackbarId: string, onDismiss: () => Observable<boolean>): void {
  const firstStepConfig = SnackbarConfiguration.progress('EXPERIENCE.CONTENT_TEMPLATES.IMPORT.SNACKBARS.STEP_1.TITLE')
    .withDescription('EXPERIENCE.CONTENT_TEMPLATES.IMPORT.SNACKBARS.STEP_1.DESCRIPTION', { fileName: file.name })
    .withDismissible(true)
    .onDismiss(() => onDismiss());
  store.next(new ShowSnackbar(snackbarId, ProgressSnackbar, firstStepConfig));
}

export function showImportProgressSnackbar(store: Store, name: string, identifier: string, onDismiss: () => Observable<boolean>): void {
  const progressConfig = SnackbarConfiguration.progress('EXPERIENCE.CONTENT_TEMPLATES.IMPORT.SNACKBARS.STEP_2.TITLE')
    .withDescription('EXPERIENCE.CONTENT_TEMPLATES.IMPORT.SNACKBARS.STEP_2.DESCRIPTION', { fileName: name })
    .withDismissible(true)
    .onDismiss(() => onDismiss());
  store.next(new ShowSnackbar(identifier, ProgressSnackbar, progressConfig));
}

export function showSuccessSnackbar(store: Store, identifier: string, name: string, openAction: () => void): void {
  store.next(new RemoveSnackbar(identifier));
  const successConfig = SnackbarConfiguration.progress('EXPERIENCE.CONTENT_TEMPLATES.IMPORT.SNACKBARS.SUCCESS.TITLE')
    .withDescription('EXPERIENCE.CONTENT_TEMPLATES.IMPORT.SNACKBARS.SUCCESS.DESCRIPTION', { fileName: name })
    .withIcon(IconConfiguration.large('check-circle-m', '').withColor(ColorConstants.SUCCESS))
    .withStatus(SnackbarState.SUCCESS)
    .withActions({
      [SnackbarState.SUCCESS]: [
        new ExecutableAction(() => {
          openAction();
          store.next(new RemoveSnackbar(identifier));
        }, 'COMMON.OPEN')
      ]
    })
    .withDismissible(true);
  store.next(new ShowSnackbar(identifier, SimpleSnackbar, successConfig));
}

export function showErrorSnackbar(store: Store, identifier: string, message: string, messageParams?: any, description?: string, descriptionParams?: any): void {
  store.next(new RemoveSnackbar(identifier));
  const errorConfig = SnackbarConfiguration.error(message, messageParams && { titleParams: messageParams });

  if (description) {
    errorConfig.withDescription(description, descriptionParams);
  }

  store.next(new ShowSnackbar(identifier, SimpleSnackbar, errorConfig));
}
