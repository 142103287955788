import { Provider } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, BaseRouteReuseStrategy, Router, RouteReuseStrategy } from '@angular/router';

export const RELOAD_COMPONENT_FRAGMENT = 'RELOAD_COMPONENT';

function shouldReloadComponent(routeSnapshot: ActivatedRouteSnapshot): boolean {
  return routeSnapshot.fragment === RELOAD_COMPONENT_FRAGMENT;
}

/**
 * Reloads the current route, forcing the component to reinitialize if {@link ExperienceRouteReuseStrategy} was configured.
 *
 * @param router - the Angular router instance
 * @param route - the currently activated route
 */
export function reloadCurrentRoute(router: Router, route: ActivatedRoute): void {
  router.navigate([], {
    relativeTo: route,
    skipLocationChange: true, // necessary as otherwise the {@link RELOAD_COMPONENT_FRAGMENT} fragment would appear in the url
    onSameUrlNavigation: 'reload',
    fragment: RELOAD_COMPONENT_FRAGMENT
  });
}

/**
 * Custom {@link RouteReuseStrategy} to newly instantiating the component although the route didn't change.
 */
export class ExperienceRouteReuseStrategy extends BaseRouteReuseStrategy {
  constructor(private alwaysFreshRoutes?: string[]) {
    super();

    this.alwaysFreshRoutes = this.alwaysFreshRoutes?.map(route =>
      route
        .split('/')
        .map(segment => (segment.startsWith(':') ? '**' : segment))
        .join('/')
    );
  }

  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (shouldReloadComponent(future)) {
      return false;
    }

    const wouldReUseRoute = super.shouldReuseRoute(future, curr);

    if (wouldReUseRoute && this.shouldRouteBeFresh(this.getFullPath(future), this.getFullPath(curr))) {
      return false;
    }

    return wouldReUseRoute;
  }

  private getFullPath(routeSnapshot: ActivatedRouteSnapshot): string {
    return routeSnapshot.pathFromRoot
      .flatMap(path => path.url.map(segment => segment.path))
      .filter(Boolean)
      .join('/');
  }

  private shouldRouteBeFresh(fullPath: string, currentFullPath: string): boolean {
    const nextRouteSegments = fullPath.split('/');
    const currentRouteSegments = currentFullPath.split('/');

    return this.alwaysFreshRoutes?.some(route => {
      const routeSegments = route.split('/');

      if (nextRouteSegments.length !== routeSegments.length) {
        return false;
      }

      // a route should be kept fresh, if all of its part match the "alwaysFreshRoute" and params (**) are not the same as in the current route
      return routeSegments.every(
        (segment, index) => (segment === '**' && nextRouteSegments[index] !== currentRouteSegments[index]) || segment === nextRouteSegments[index]
      );
    });
  }
}

/**
 * Use this provider to add the {@link ExperienceRouteReuseStrategy}, to be able to reload the current route and newly instantiate the component.
 *
 * Examples:
 *
 * Defined alwaysFreshRoutes: ['content-template/:id/designer']
 *
 * - from `content-template/123/designer` to `content-template/123/designer` -> no refresh as id is the same
 * - from `content-template/123/designer` to `content-template/456/designer` -> refresh as id is not the same
 * - from `content-template/123/designer` to `content-template/123/designer?magicMenu=true` -> no refresh as same id (query params are ignored)
 * - from `content-template/123/designer` to `content-template/123/designer/subpath` -> no refresh, because same id
 * - from `content-template/123/designer/subpath` to `content-template/123/designer` -> no refresh, because same id
 * - from `content-template/456/designer/page1` to `content-template/123/designer` -> refresh as id is not the same
 * - from `content-template/456/designer` to `content-template/123/designer/page1` -> no refresh as the future route does not match the defined route (has more
 * segments)
 *
 * @param alwaysFreshRoutes list of routes for which to ignore the reuse strategy and always reload the component if their params change
 */
export function provideReuseStrategy(alwaysFreshRoutes?: string[]): Provider {
  return {
    provide: RouteReuseStrategy,
    useValue: new ExperienceRouteReuseStrategy(alwaysFreshRoutes)
  };
}
