import { DestroyRef } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { debounceTime as debounceTimeFn, distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { DomHelper } from './dom-helper';

/**
 * Calculates a new batch size based on the container-element and an item size (width * height or width or height) given by `getItemSize`.
 *
 * @param getItemSize function that should provide the current size of the elements to calculate the batch size for (will be multiplied with 1.2 if
 *   width*height is used)
 * @param stop$ observable that should emit if the batchSize should not be evaluated anymore
 * @param options optional configurations {@see BatchSizeCalcOptions}
 */
export function calculateBatchSize(getItemSize: () => number, stop$: Observable<void> | DestroyRef, options?: BatchSizeCalcOptions): Observable<number> {
  const container = options?.containerElement || window.document.body;
  if (!container) {
    return EMPTY;
  }

  return DomHelper.registerResizeObserver(container, stop$).pipe(
    debounceTimeFn(options?.debounceTime ?? 300),
    startWith(void 0 as void),
    map(() => getNrOfItemsPerBatch(container.clientWidth, container.clientHeight, getItemSize, options)),
    map(batchSize => Math.max(batchSize, 1)),
    map(batchSize => Math.min(batchSize, options.maxBatchSize ?? Number.MAX_SAFE_INTEGER)),
    distinctUntilChanged()
  );
}

function getNrOfItemsPerBatch(innerWidth: number, innerHeight: number, getItemSize: () => number, options?: BatchSizeCalcOptions): number {
  if (options?.dimension === 'width') {
    return calculateItems(innerWidth + (+options.offset || 0), getItemSize());
  }

  if (options?.dimension === 'height') {
    return calculateItems(innerHeight + (+options.offset || 0), getItemSize());
  }

  const area = innerHeight * innerWidth;

  const nrOfItems = area / (getItemSize() ?? 100) * 1.2;

  return Math.floor(nrOfItems);
}

function calculateItems(size: number, sizeItem: number): number {
  const nrOfItems = size / (sizeItem ?? 100);
  return Math.floor(nrOfItems);
}

export interface BatchSizeCalcOptions {
  /**
   * Specify the debounceTime before the next batch-size is calculated after a resize.
   */
  debounceTime?: number;
  /**
   * Container element for the content. If no element is given, the batch size will be calculated based on the body.
   */
  containerElement?: HTMLElement;
  /**
   * Specify if the batchSize should be based on only the width, height or if it should take both into account
   */
  dimension?: 'width' | 'height' | 'both';
  /**
   * Define extra size added to the dimension of the element.
   */
  offset?: number;
  /**
   * Define a maximum allowed batch size. If not defined, max value is Number.MAX_SAFE_INTEGER.
   */
  maxBatchSize?: number;
}
